import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
      path: '/login/start',
      name: 'StartView',
      component: () => import('@/components/pages/login/StartView.vue'),
      meta:{
        layout: 'blank',
        blankTheme: 1,
        unAuth: true,
        back: 'back',
        isWlStyle: true,
      }
    },
    {
      path: '/login',
      name: 'LoginView',
      component: () => import('@/components/pages/login/LoginView.vue'),
      meta:{
        layout: 'blank',
        blankTheme: 2,
        unAuth: true,
        back: { name: 'StartView' },
        isWlStyle: true,
      }
    },
    {
      path: '/register/start',
      name: 'RegisterView',
      component: () => import('@/components/pages/register/RegisterStartView.vue'),
      meta:{
        layout: 'blank',
        blankTheme: 2,
        unAuth: true,
        back: { name: 'StartView' },
        isWlStyle: true,
      }
    },
    {
        path: '/register/external/:platform',
        name: 'RegisterExternal',
        component: () => import('@/components/pages/register/RegisterExternalView.vue'),
        meta:{
            layout: 'blank',
            blankTheme: 2,
            unAuth: true,
        }
    },
    {
      path: '/register/email',
      name: 'RegisterEmailView',
      component: () => import('@/components/pages/register/RegisterEmailView.vue'),
      meta:{
        layout: 'blank',
        blankTheme: 2,
        unAuth: true,
        back: { name: 'RegisterView' },
        isWlStyle: true,
      }
    },
    {
        path: '/register/email/wait',
        name: 'RegisterEmailWaitView',
        component: () => import('@/components/pages/register/RegisterEmailWaitView.vue'),
        meta:{
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
            notLogin: true,
        }
    },
    {
        path: '/expired',
        name: 'RegisterEmailExpiredView',
        component: () => import('@/components/pages/register/RegisterEmailExpiredView.vue'),
        meta:{
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
            notLogin: true,
        }
    },
    {
        path: '/register/success',
        name: 'RegisterSuccessView',
        component: () => import('@/components/pages/register/RegisterSuccessView.vue'),
        meta:{
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
        }
    },
    {
        path: '/invalid',
        name: 'RegisterErrorView',
        component: () => import('@/components/pages/register/RegisterErrorView.vue'),
        meta:{
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
        }
    },
    {
        path: '/register/wallet/code/:stage',
        name: 'WalletConfigCodeView',
        component: () => import('@/components/pages/wallet/WalletConfigPwView.vue'),
        meta:{
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
        }
    },
    {
        path: '/verified',
        name: 'RegisterVerifyView',
        component: () => import('@/components/pages/register/VerifyView.vue'),
        meta: {
            unAuth: true,
            notLogin: true,
        }
    },
    {
        path: '/forgot/pwd',
        name: 'ForgotPwdView',
        component: () => import('@/components/pages/login/ForgetPwdView.vue'),
        meta: {
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
        }
    },
    {
        path: '/reset/password',
        name: 'ResetPwdView',
        component: () => import('@/components/pages/login/ResetPwdView.vue'),
        meta: {
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
            notLogin: true,
        }
    },
    {
        path: '/termsofservice',
        name: 'TermsOfServiceView',
        component: () => import('@/components/pages/auth/TermsView.vue'),
        meta: {
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
            notLogin: true,
            terms: 'service'
        }
    },
    {
        path: '/privacy',
        name: 'TermsOfPrivacyView',
        component: () => import('@/components/pages/auth/TermsView.vue'),
        meta: {
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
            notLogin: true,
            terms: 'privacy'
        }
    },
    {
        path: '/marketing',
        name: 'TermsOfMarketingView',
        component: () => import('@/components/pages/auth/TermsView.vue'),
        meta: {
            layout: 'blank',
            blankTheme: 3,
            unAuth: true,
            notLogin: true,
            terms: 'marketing'
        }
    },
    {
        path: '/in_app/route',
        name: 'InAppRouteView',
        component: () => import('@/components/pages/auth/InAppRouteView.vue'),
        meta:{
            layout: 'blank',
            blankTheme: 2,
            unAuth: true,
            notLogin: true,
        }
    },
  {
    path: '/identify/nice',
    name: 'IdentifyNiceView',
    component: () => import('@/components/pages/auth/IdentifyNiceView.vue'),
    meta:{
      layout: 'blank',
      blankTheme: 3,
    }
  },
];

export default routes
