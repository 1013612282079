import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/nft/:id',
    name: 'NftDetailView',
    component: () => import('@/components/pages/detail/NftDetailView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 4,
      back: { name: 'HomeView' }
    }
  },
  {
    path: '/nft/:id/seat',
    name: 'NftSeatMapView',
    component: () => import('@/components/pages/detail/NftSeatMapView.vue'),
    meta: {
      blankTheme: 4,
    }
  },
  {
    path: '/nft/locked/:id',
    name: 'NftLockedDetailView',
    component: () => import('@/components/pages/detail/NftLockedDetailView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 4,
      back: { name: 'HomeView' },
    }
  },
  {
    path: '/nft/sales/:id',
    name: 'SaleNftDetailView',
    component: () => import('@/components/pages/nft/sale_nft/SaleNftDetailView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 4,
    }
  },
  {
    path: '/nft/qtnr/:id',
    name: 'NftQtnrView',
    component: () => import('@/components/pages/nft/qtnr/NftQtnrView.vue'),
    meta: {
      layout: 'blank',
      blankTheme: 0,
    }
  },
];

export default routes
