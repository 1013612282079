
import { defineComponent } from 'vue';
import { useNftNtfc } from '@/script/ntfc/ntfcScript';
import { useModal } from '@/script/common/modalScript';
import BottomSlideSheet from '@/components/common/modal/BottomSlideSheet.vue';
import ReceiveNftComp from '@/components/pages/popup/comp/ReceiveNftComp.vue';
import UserInviteComp from '@/components/pages/popup/comp/UserInviteComp.vue';
import SaleCpltComp from '@/components/pages/popup/comp/SaleCpltComp.vue';
import { store } from '@/store';
import {
  NtfcNftReceiveResponse,
  NtfcUserInviteResponse,
} from '@/models/response/ntfcResponseModel';
import {useRoute, useRouter} from 'vue-router';

export default defineComponent({
  name: 'NftNtfcView',
  components: { BottomSlideSheet, ReceiveNftComp, UserInviteComp, SaleCpltComp },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { isOpen, toggleModal } = useModal();
    const { list, info, getNtfcList, getNtfc } = useNftNtfc();

    const startPolling = () => {
      const interval = setInterval(async () => {
        try {
          if (store.state.auth.id) {
            try {
              await getNtfcList();
            } catch(e: any) {
              console.error(e)
              if (e?.response?.status === 401) {
                clearInterval(interval);
              }
            }
          }

          if (list.value.length > 0) {
            await getNtfc(Number(list.value.pop()));
            toggleModal(true);
            clearInterval(interval);
          }
        } catch (e: any) {
          clearInterval(interval);
        }
      }, 1000);
    };
    startPolling();

    const onOk = async () => {
      toggleModal(false);
      if (list.value.length > 0) {
        await getNtfc(Number(list.value.pop()));
        toggleModal(true);
      } else {
        if (['NFT_RECEIVE', 'NFT_SELL'].includes(info.value.type) && route.name === 'HomeView') {
          store.state.status.nftList.refreshWait = true
        }
        toggleModal(false);
        startPolling();
      }
    };

    return { list, isOpen, info, onOk };
  },
});
